import { useGetAdsQuery } from '../../../../api';
import { useMemo } from 'react';
import type { FunctionComponent } from 'react';
import { useAdsTable } from './useAdsTable';
import { TableContainer } from '@chakra-ui/react';
import { BaseTable } from '@mcrp/web-components-lib';

export const AdsTable: FunctionComponent = () => {
  const { data } = useGetAdsQuery();
  const ads = useMemo(() => data ?? [], [data]);
  const table = useAdsTable(ads);

  return (
    <TableContainer overflow={'auto'} minW={'full'}>
      <BaseTable table={table} emptyMessage={'No Ads Available'} />
    </TableContainer>
  );
};
