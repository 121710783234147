import { api } from '@mcrp/web-api';
import type { AdResponse } from '@mcrp/types';
import { mockAdResponse } from '@mcrp/type-mocks';

const enhanced = api.enhanceEndpoints({ addTagTypes: ['Ad'] });

export const adsApi = enhanced.injectEndpoints({
  endpoints: (build) => ({
    // FUTURE: QBED-143/QBED-144 Add filtering/sorting to this request
    getAds: build.query<AdResponse[], void>({
      // query: () => ({
      //   url: `ads`,
      // }),
      // TODO: Remove this once backend is implemented
      queryFn: () => ({
        data: [mockAdResponse(1), mockAdResponse(2), mockAdResponse(3)],
      }),
      providesTags: ['Ad'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAdsQuery } = adsApi;
