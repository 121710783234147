import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { AdResponse, ExternalSource, Status } from '@mcrp/types';
// import { formatDateDisplay } from '@mcrp/web-components-lib';

const sourceDisplayMap: Record<ExternalSource['type'], string> = {
  Pathmatics: 'Pathmatics',
};

const statusDisplayMap: Record<Status['type'], string> = {
  New: 'New',
  Rejected: 'Rejected',
  Confirmed: 'Confirmed',
};

export const useAdsTable = (ads: AdResponse[]) => {
  const columnHelper = createColumnHelper<AdResponse>();

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      header: 'ID',
      sortingFn: 'alphanumeric',
      minSize: 340,
      cell: (info) => info.cell.getValue(),
    }),
    columnHelper.accessor((row) => sourceDisplayMap[row.source.type], {
      id: 'source',
      header: 'Source',
      sortingFn: 'alphanumeric',
      cell: (info) => info.cell.getValue(),
    }),
    columnHelper.accessor((row) => statusDisplayMap[row.status.type], {
      id: 'status',
      header: 'Status',
      sortingFn: 'alphanumeric',
      cell: (info) => info.cell.getValue(),
    }),
    // columnHelper.accessor((row) => row.brand?.name, {
    //   id: 'brand',
    //   header: 'Brand',
    //   sortingFn: 'alphanumeric',
    //   cell: (info) => info.cell.getValue(),
    // }),
    columnHelper.accessor((row) => row.product?.name, {
      id: 'product',
      header: 'Product',
      sortingFn: 'alphanumeric',
      cell: (info) => info.cell.getValue(),
    }),
    // columnHelper.accessor((row) => row.category?.name, {
    //   id: 'category',
    //   header: 'Category',
    //   sortingFn: 'alphanumeric',
    //   cell: (info) => info.cell.getValue(),
    // }),
    // columnHelper.accessor((row) => row.duration, {
    //   id: 'duration',
    //   header: 'Duration',
    //   sortingFn: 'alphanumeric',
    //   cell: (info) => info.cell.getValue(),
    // }),
    // columnHelper.accessor((row) => formatDateDisplay(row.createdAt), {
    //   id: 'creationDate',
    //   header: 'Creation Date',
    //   sortingFn: 'alphanumeric',
    //   cell: (info) => info.cell.getValue(),
    // }),
    // columnHelper.accessor((row) => formatDateDisplay(row.updatedAt), {
    //   id: 'updatedDate',
    //   header: 'Updated Date',
    //   sortingFn: 'alphanumeric',
    //   cell: (info) => info.cell.getValue(),
    // }),
    // columnHelper.accessor((row) => formatDateDisplay(row.airedAt), {
    //   id: 'airedAt',
    //   header: 'Air Date',
    //   sortingFn: 'alphanumeric',
    //   cell: (info) => info.cell.getValue(),
    // }),
  ];

  return useReactTable({
    columns,
    data: ads,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // FUTURE: QBED-143, QBED-144 Re-enable these lines to allow for filtering and pagination
    // getFilteredRowModel: getFilteredRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      sorting: [{ id: 'id', desc: false }],
      // pagination: {
      //   pageSize: 20,
      // },
    },
  });
};
