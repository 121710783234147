import type { FunctionComponent } from 'react';
import {
  Page,
  PageBanner,
  PageBody,
  PageFooter,
  PagePanel,
} from '@marketcast/ui-kit';
import { Header } from '../../../../common/components/header';
import { AdsTable } from './table';

export const AdsListPage: FunctionComponent = () => {
  return (
    <Page>
      <Header title={'Ads'} />
      <PageBanner />
      <PageBody>
        <PagePanel>
          <AdsTable />
        </PagePanel>
      </PageBody>
      <PageFooter />
    </Page>
  );
};
